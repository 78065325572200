//初始页签增加初始路由
export const visitedViews=(state) => {
    const INIT={
          path:"/home",
          name:"home",
          meta:{
            //   匹配规则
            name:"信息配置",
            icon:'el-icon-s-home',
          }
       
            }
    // currentRouter();
   
    const flag=state.visitedviews.some(v=>v.path===INIT.path);//判断数组中是否已经存在该路由
    if (!flag){
        // console.log(INIT,"INIT----");
        state.visitedviews.push({
        name: INIT.name,
        path: INIT.path,
        meta: INIT.meta
      });
    }
    return state.visitedviews;
  };

  import router from '../router';
//获取路由
export const currentRouter=()=>{
  
  const PATH=router.history.current;//当前路由
 

  // const ROUTER_LIST=router.options.routes;//index所有的子级路由.
  // console.log(ROUTER_LIST);
//   console.log(router.options);
//   const NAVIGATION=[];//导航栏列表
//   for (let i=0;i<ROUTER_LIST.length;i++){
//     if (ROUTER_LIST[i].meta.bar){//如果bar为true,则是添加至导航栏
//       NAVIGATION.push(ROUTER_LIST[i])
//     }
//   }
//   const INIT=NAVIGATION[0];//初始路由
//   return [PATH,INIT,NAVIGATION];
return PATH;
};