import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};//解决element-ui的子菜单项点击两下出现 Navigati

// 初始化页面
const routes=[
    {
        path:"/login",
        name:"login",
        // component: resolve => require(['@/pages/login'], resolve),
        component: () => import ('@/pages/login'),
    }
]
export const DynamicRoutes=[
    {
        path:"",
        component: resolve => require(['@/pages/layout'], resolve),
        // component: () => import ('@/pages/layout'),
        name:"container",
        redirect: "home",
        // redirect: {
        //     name: "home",
        // },
        meta:{
            requiresAuth:true,//是否需要登录
            name:""
        },
        children:[
            {
              path:"home",
              component: resolve => require(['@/pages/home'], resolve),
            // component: () => import ('@/pages/home'),
              name:"home",
              meta:{
                //   匹配规则
                name:"信息配置",
                icon:'el-icon-s-home',
              }
            }
        ]
    },
    {
        path:"/403",
        component: resolve => require(['@/pages/errorPage/403'], resolve)
        // component: () => import ('@/pages/errorPage/403'),

    },
    {
        path:"*",
        component: resolve => require(['@/pages/errorPage/404'], resolve)
        // component: () => import ('@/pages/errorPage/404'),

    }
]

const router = new Router({
    // mode: 'history', 
    routes
  })

  export default router

// const createRouter = () => new Router({
//     // mode: 'history', // require service support
//     scrollBehavior: () => ({ y: 0 }),
//     routes: routes
//   })
//   const router = createRouter()
//   router.selfaddRoutes = function (params){
//     router.matcher = new Router().matcher;
//     router.addRoutes(params)
//   }
//   export default router