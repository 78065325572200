<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  
}
 @import "./assets/sass/index";
.el-tabs__nav-next, .el-tabs__nav-prev {
    position: absolute;
    cursor: pointer;
    line-height: 44px;
    font-size: 18px  !important;
    color: #909399;
}

#app {
 
}
.flex{
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */

  display: -webkit-flex; /* 新版本语法: Chrome 21+ */

  display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}
.flex-1 {
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
}
.flex-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.justifyEnd{
  justify-content: flex-end;
}

</style>
