import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./router/permission"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueParticles from 'vue-particles'  
Vue.use(VueParticles)  
Vue.use(ElementUI);
Vue.config.productionTip = false
/*第一层if判断生产环境和开发环境*/
if (process.env.NODE_ENV === 'production') {
  /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
  if (process.env.VUE_APP_FLAG === 'pro') {
      //production 生产环境
      Vue.prototype.baseURL="http://ipad.yynk.cn"
      console.log( "生产环境");
  } else {
      //test 测试环境
      // Vue.prototype.baseURL="http://192.168.0.90:801"
      // console.log( "测试环境");
  }
} else {
  //dev 开发环境
  // axios.defaults.baseURL = 'http://192.168.0.152:8102';
  Vue.prototype.baseURL="http://192.168.0.90:801"
  console.log( "本地开发环境");
}


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
