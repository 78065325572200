//页签切换路由时

export function changeVisitedViews({ commit }, view) {

    // console.log("改变路由");
    return commit('CHANGE_VISITED_VIEWS', view);//去触发 CHANGE_VISITED_VIEWS，并传入参数
  }
  
  //添加页签路由
  export function addVisitedViews({ commit }, view) {
  
    // console.log("添加页签路由");
    
    return commit('ADD_VISITED_VIEWS', view);//去触发ADD_VISITED_VIEWS，并传入参数
  }
  //关闭页签--删除路由数据的方法
  export function delVisitedViews({ commit, state }, view) {
    return new Promise((resolve) => {
      commit('DEL_VISITED_VIEWS', view);
      resolve([...state.visitedviews]);//resolve方法：成功后回调的方法,返回新的state.visitedviews
    })
  }
  
  //删除所有页签--
  export function delAllVisitedViews({ commit, state }, view) {
    return new Promise((resolve) => {
      commit('DEL_All_VISITED_VIEWS', view);
      resolve();//resolve方法：成功后回调的方法,返回新的state.visitedviews
    })
  }