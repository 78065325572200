import router from "./index"
import store from "../store/index"
import {addDynamicMenuAndRoutes} from "../utils/recursion-router"
import dynamicRouters from "./dynamic_router"
router.beforeEach((to, from, next) => {
   if(!store.state.UserToken){
        // 未登陆     页面是否需要登陆
        if(to.matched.length > 0 &&!to.matched.some(record=> record.meta.requiresAuth)){
            // 不需要登陆直接往下
            next()
        }else{
            // 需要登陆
            next({
                path:"/login"
            })
        }
   }else{
    //    用户已经登录  路由访问权限
       if(!store.state.permission.permissionList){
                
            store.dispatch('permission/FETCH_PERMISSION').then(()=>{
                console.log("用户已经登录  路由访问权限",to.path);
                next({
                    path:to.path
                })
            })
            
            // addDynamicMenuAndRoutes(dynamicRouters)
       }else{
           //    store 存在权限
           if(to.path!=="/login"){
            next()
           }else{
               next(from.fullPath)
           }
            
          
       }
  
   }
    
})
