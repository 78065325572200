export default {
    LOGIN_IN(state, token) {
       
        state.UserToken = token
       
    },
    LOGIN_OUT(state) {
        state.UserToken = ''
        state.currentMenu="home"
        // console.log();
    },

    COLLAPSE(state) {
        //展开或收起左侧菜单
        state.isCollapse = !state.isCollapse;
    },
    //切换路由时,改变左侧菜单栏
    CHANGE_VISITED_VIEWS(state, view) {
      
        state.currentMenu = view.name;
        console.log( view);
    },
    ADD_VISITED_VIEWS(state, view) {
        /*  some() 方法用于检测数组中的元素是否满足指定条件,
          如果有一个元素满足条件，则表达式返回true , 剩余的元素不会再执行检测。
          如果没有满足条件的元素，则返回false */
        const flag = state.visitedviews.some(v => v.path === view.path);//判断数组中是否已经存在该路由
        // 如果存在当前路由,则返回false,否则添加进去
        if (flag) {
            return false;
        }
        else {
            state.visitedviews.push({
                name: view.name,
                path: view.path,
                meta: view.meta
            })
        }
    },

    DEL_VISITED_VIEWS(state, view) {
        //关闭页签--删除路由数据的方法
        //entries() 方法返回一个数组的迭代对象，该对象包含数组的键值对 (key/value)。
        for (let [i, v] of state.visitedviews.entries()) {
          
            if (v.name === view.name) {//i代表索引,v代表对应的对象
               
                state.visitedviews.splice(i, 1);
               
                break
            }
        }
      
    },


    DEL_All_VISITED_VIEWS(state, view) {
        //删除所有页签--
        state.visitedviews = []
    }
}

