export default {
    get UserToken(){
        return localStorage.getItem('token')
    },
    set UserToken(val){
        localStorage.setItem('token',val)
    },
  
    isCollapse: false,//面板展开收起
    visitedviews: [],//存放所有浏览过的且不重复的路由数据
    currentMenu: 'home',//高亮
}