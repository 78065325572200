/*
*方法一:比对路由权限
*方法二： 指定返回的默认路由
*/

/*
*@parram{array} userRouter 后台返回的路由配置表  json
*@parram{array} allRouter 前端配置好的路由
*@return{array} realRouter 过滤后符合条件的路由
*/
import router from "../router/index"
export function recursionRouter(userRouter=[],allRouter=[]){
    var realRouter=[]
    allRouter.forEach((v,i)=>{
         
        userRouter.forEach((item,index)=>{
           
           
            if(item.name===v.meta.name){
               
                if(item.children && item.children.length>0){
                 
                    v.children=recursionRouter(item.children,v.children)
                }
                realRouter.push(v)
            }
           
        })
    })
    
    return realRouter
}

// 设置默认路由
export function setDefaultRoute(routers){
   
    routers.forEach((v,i)=>{
       
        if(v.children && v.children.length>0){
            v.redirect={name:v.children[0].name}
            setDefaultRoute(v.children )
        }
        
        
    })

}

