const dynamicRouters=[
    // {
    //     path:"/order",
    //     component: resolve => require(['@/pages/order-manage'], resolve),
    //     // component: () => import ('@/pages/order-manage'),
    //     name:"order-manage",
    //     meta:{
    //         name:"订单管理",
    //         icon:"el-icon-s-home"
    //     },
    //     children:[
    //         {
    //             path:"list",
    //             name:"order-list",
    //             component: resolve => require(['@/pages/order-manage/order-list'], resolve),
    //             // component: () => import ('@/pages/order-manage/order-list'),
    //             meta:{
    //                 name:"订单列表",
    //                 icon:"el-icon-s-home"
    //             },
    //         },
    //         {
    //             path:"product",
    //             name:"product-manage",
    //             component: resolve => require(['@/pages/order-manage/product-manage'], resolve),
    //             // component: () => import ('@/pages/order-manage/product-manage'),
    //             meta:{
    //                 name:"生产管理",
    //                 icon:"el-icon-s-home"
    //             },
               
    //             children:[
    //                 {
    //                     path:"list",
    //                     name:"product-list",
    //                     component: resolve => require(['@/pages/order-manage/product-manage/production-list'], resolve),
    //                     // component: () => import ('@/pages/order-manage/product-manage/production-list'),
    //                     meta:{
    //                         name:"生产列表",
    //                         icon:"el-icon-s-home"
    //                     },
    //                 },
    //                 {
    //                     path:"review",
    //                     name:"review-manage",
    //                     component: resolve => require(['@/pages/order-manage/product-manage/review-manage'], resolve),
    //                     // component: () => import ('@/pages/order-manage/product-manage/review-manage'),
    //                     meta:{
    //                         name:"审核管理",
    //                         icon:"el-icon-s-home"
    //                     },
    //                 },
    //             ]
    //         },
    //         {
    //             path:"returnGoods",
    //             name:"return-goods",
    //             component: resolve => require(['@/pages/order-manage/return-goods'], resolve),
    //             // component: () => import ('@/pages/order-manage/return-goods'),
    //             meta: { 
    //                 name:"退货管理",
    //                 icon:"el-icon-s-home"
    //              }
    //         }
            
    //     ]
    // },
    // {
    //     path:"/goods",
    //     name:"goods",
    //     component: resolve => require(['@/pages/goods-manage'], resolve),
    //     // component: () => import ('@/pages/goods-manage'),
    //     meta:{
    //         name:"产品管理",
    //         icon:"el-icon-s-home"
    //     },
    //     children:[
    //         {
    //             path:"list",
    //             name:"goods-list",
    //             component: resolve => require(['@/pages/goods-manage/goods-list'], resolve),
    //             // component: () => import ('@/pages/goods-manage/goods-list'),
    //             meta: { 
    //                 name:"产品列表",
    //                 icon:"el-icon-s-home"
    //              }
    //         },
    //         {
    //             path:"classify",
    //             name:"goods-classify",
    //             component: resolve => require(['@/pages/goods-manage/goods-classify'], resolve),
    //             // component: () => import ('@/pages/goods-manage/goods-classify'),
    //             meta: { 
    //                 name:"产品分类",
    //                 icon:"el-icon-s-home"
    //              }
    //         }
    //     ]
    // }
]
export default dynamicRouters

