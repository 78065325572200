import Vue from 'vue'
import Vuex from 'vuex'
import state from "./state"
import mutations from "./mutations"
import  * as getters from "./getters"
import * as actions from './actions';
import modules from "./modules"
import createPersistedState from "vuex-persistedstate";//vuex持久化
Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            reducer(data) {
            return {
                // 设置储存state中的visitedviews
                visitedviews: data.visitedviews,//存放所有浏览过的且不重复的路由数据
                currentMenu:data.currentMenu,//高亮路由
                UserToken:data.UserToken
            }
            }
        })
    ],//持久化vuex防止刷新
    state,
    getters,    
    mutations,
    actions,
    modules,
})

export default store 