
import router,{DynamicRoutes} from "../../router/index"
import dynamicRouter from "../../router/dynamic_router"
import { recursionRouter, setDefaultRoute,addDynamicMenuAndRoutes } from "../../utils/recursion-router"

export default {
    namespaced: true,//指定model  不是全局可见
    state: {
        permissionList: null,
        sidebarMenu: [],//导航菜单
        
    },
    getters: {

    },
    mutations: {
        SET_PREMISSION(state, routes) {
            state.permissionList = routes
        },
        CLEAR_PREMISSION(state) {
            state.permissionList = null
        },
        SET_MENU(state, menu) {
          
            state.sidebarMenu = menu
        },
        CLEAR_MENU(state) {
            state.sidebarMenu = []
        },
      
    },
    // 异步访问
    actions: {
         FETCH_PERMISSION({ commit, state }) {
            // 网络请求
            let permissionList = [
                {

                    name: "订单管理",
                    children: [
                        {
                            name: "订单列表",
                        },
                        {
                            name: "生产管理",
                            children: [
                                {
                                    name: "生产列表",
                                },
                                {
                                    name: "审核管理",
                                },
                            ]
                        },
                        {
                            name: "退货管理",
                        }

                    ]
                },
                 {
                    name: "产品管理",
                    children: [
                        {
                            name: "产品列表",
                        },
                        {
                            name: "产品分类",
                        }
                    ]
                }
            ]
           
           
            
            // 根据请求的身份分配不同的路由权限
            // 筛选
            let routes = recursionRouter(permissionList, dynamicRouter)
           
            // console.log(routes);
            let MinContiner = DynamicRoutes.find(v => v.path === "")
            // console.log(MinContiner);
            
            let children = MinContiner.children 
            children.push(...routes)
            // console.log(children);

            // //    生成菜单
            commit('SET_MENU', children)
            // // 设置默认路由
            setDefaultRoute([MinContiner])
            // // 初始化路由
            let initRouters = router.options.routes;

            // addDynamicMenuAndRoutes(DynamicRoutes)
            router.addRoutes(DynamicRoutes)
          
            // router.addRoute(DynamicRoutes)
            commit("SET_PREMISSION", [...initRouters, ...DynamicRoutes])
        }
    }
}